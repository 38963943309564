import React from "react"
import {graphql} from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Link from "gatsby-link";

export const query = graphql`
    query($video_id: String!) {
      logo: file(relativePath: { eq: "thumbnail.png" }) {
        publicURL
      },
      allDataJson: allDataJson {
        nodes {
          pediatricians {
            videos {
              other_videos
            }
            footnote
          }
        }
      }
      video: vimeoVideo(id: {eq: $video_id}) {
        id
        description
        iframe
        title
        url
        thumbnail {
          medium
        }
      }
      allVimeoVideo: allVimeoVideo(sort: {fields: title}) {
        nodes {
          id
          description
          iframe
          title
          url
          thumbnail {
            medium
          }
        }
      }
    }
`

const IndexPage = ({data, pageContext}) => {
  return (
    <Layout>
      <SEO title="Pediatra"/>
      <div className={"page bg-white overflow-hidden"}>

        <Row className={"bg-white justify-content-center pt-page mb-4"}>
          <Col className={"col-auto"}>
            <h1 className={"text-center px-2"} dangerouslySetInnerHTML={{__html: data.video.title}} />
          </Col>
        </Row>

        <Row className={"justify-content-center"}>
          <Col className={"col-md-8 video-box"}>
            <div className={"embed-responsive embed-responsive-16by9"} dangerouslySetInnerHTML={{__html: data.video.iframe}} />
          </Col>
        </Row>

        <Row className={"bg-light mt-4"}>
          <Col className={"col-12 text-center pt-4 pb-3"}>
            <h3 className={"mb-0"}
                dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.videos.other_videos}}/>
          </Col>
          <Col xs={{span: 10, offset: 1}} md={{span: 8, offset: 2}} className={"px-3 pt-0 pb-3 align-items-center"}>
            <Row className={""}>
              {data.allVimeoVideo.nodes.filter((v) => v.id !== data.video.id && v.description !== 'Camilla') .map((video, i) => (
                <Col key={i} xs={{ span: 10 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 3 }} className={"p-3 align-items-center"}>
                  <Link to={`/pediatra/videos/${video.id}`} className={"video-link"}>
                    <div>
                      <img src={data.logo.publicURL} alt={video.title} className={"mb-2"} style={{width: "100%"}}/>
                      <i className="fas fa-play-circle"/>
                    </div>
                    <p className={"text-center video-title-wrap"}>{video.title}</p>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>


        <Row className={"bg-white section-5 justify-content-center pt-4 pb-4"}>
          <Col xs={10} md={{span: 8}} className={"text-center"}>
            <p className={"mb-2"} dangerouslySetInnerHTML={{__html: data.allDataJson.nodes[0].pediatricians.footnote}}/>
          </Col>
        </Row>

      </div>
    </Layout>
  )
}

export default IndexPage
